import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
// import Header from '../components/HeaderWhite/header'
// import Footer from '../components/Footer/footer'
import MobileMenu from '../components/FirstBlock/mobileMenu'
import SignUp from '../components/ModalSignUp/index'
import { Helmet } from "react-helmet";

import logo from '../components/img/SignUppng.png'

// const content = `Create free account - use Duefocus time tracker and record working hours. Boost efficiency with time management app.`
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const SignUpPage = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      {/* <title>Sign Up - DueFocus - Time Tracking App</title> */}
      <title>Sign Up - duefocus</title>
      <meta name="description" content={content} />
      <meta property="og:image" content={logo} />
      <meta name="twitter:image:src" content={logo} />
      <meta name="robots" content="noindex, nofollow" />
      <link rel="canonical" href="https://www.duefocus.com/sign-up/" />
    </Helmet>
    <SignUp>
      <MobileMenu />
    </SignUp>
  </>
)

export default SignUpPage