import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

export const GASignUpButton = () => {
  trackCustomEvent({
    category: "button",
    action: "click",
    label: "Create free account",
  })
};
export const GASignUpGoogle = () => {
  trackCustomEvent({
    category: "buttonGoogle",
    action: "click",
    label: "Create google",
  })
};
export const GASignUpGitHub = () => {
  trackCustomEvent({
    category: "buttonGitHub",
    action: "click",
    label: "Create github",
  })
};

export const GASignUpGitLab = () => {
  trackCustomEvent({
    category: "buttonGitLab",
    action: "click",
    label: "Create gitlab",
  })
};
export const GASignUpTrello = () => {
  trackCustomEvent({
    category: "buttonTrello",
    action: "click",
    label: "Create trello",
  })
};